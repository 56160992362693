import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { resetPassword, deleteAccount } from '../../store/actions/authActions'



class Profile extends Component {

    handleClick = (e) => {
        e.preventDefault();
        alert("This feature is not yet implemented")
    }

    handleResetPassword = (e) => {
        e.preventDefault();
        this.props.resetPassword(this.props.auth.email);
        document.getElementById("reset-pwd").classList.add('disabled');
        document.getElementById("reset-pwd-message").innerHTML = "Återställningslänk skickad till din angivna mailadress"
    }

    handleDeleteAccount = (e) => {
        e.preventDefault();
        if (window.confirm("Är du säker på att du vill ta bort ditt konto?")) {
            this.props.deleteAccount();
            document.getElementById("reset-pwd").classList.add('disabled');
        } else {
        }
    }

    render() {
        const { profile, auth } = this.props;
        if (!auth.uid) return <Redirect to='/' />
        const verified = auth.emailVerified ? (
            <p className="green darken-2">Din mailadress {auth.email} är verifierad</p>
        ) : (
                <p className="red">Du har inte verifierat din mailadress {auth.email} och kan därför inte skapa recept.</p>
            )

        return (

            <div className="container center">
                <h4 className="grey-text text-darken-3 center">Mitt Konto</h4>
                <p>{profile.firstName} {profile.lastName}</p>

                {verified}
                <button id="reset-pwd" className="waves-effect waves-dark btn red z-depth-1" onClick={this.handleResetPassword}>Återställ Lösenord</button>
                <button className="waves-effect waves-dark btn red z-depth-1" onClick={this.handleDeleteAccount}>Ta bort konto</button>
                <p id="reset-pwd-message"></p>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (email) => dispatch(resetPassword(email)),
        deleteAccount: () => dispatch(deleteAccount())
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(Profile)
