import authReducer from './authReducer'
import recipeReducer from './recipeReducer'
import commentReducer from './commentReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import newsReducer from './newsReducer';
import storageReducer from './storageReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    recipe: recipeReducer,
    comment: commentReducer,
    news: newsReducer,
    storage: storageReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer