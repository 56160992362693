import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn, googleSignIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'


class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.googleSignIn();
    }

    render() {
        const { authError, auth } = this.props;
        if (auth.uid) return <Redirect to='/' />

        return (

            <div className="container">
                <h4 className="grey-text text-darken-3 center">Logga in</h4>

                <div className="googleSignIn center">
                    <br />
                    <button id="google-signin" className="waves-effect waves-dark btn blue z-depth-1" onClick={this.handleClick}>Logga in med Google</button>
                </div>

                <p className="grey-text center">Eller med mail-adress och lösenord:</p><br />

                <form onSubmit={this.handleSubmit} className="white">
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Lösenord</label>
                        <input type="password" id="password" onChange={this.handleChange} />
                    </div>
                    <div className="input-field center">
                        <button className="waves-effect waves-dark btn pink lighten-1 z-depth-1">Logga in</button>
                        <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        googleSignIn: () => dispatch(googleSignIn())
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
