import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Notifications = (props) => {
    const { notifications } = props;

    return (
        <div className="section">
            <div className="card z-depth-0 project-summary">
                <div className="grey-text text-darken-3">
                    <span className="card-title">Aktivitet</span>
                    <ul className="notifications">
                        {notifications && notifications.map(item => {
                            if (item.recipe) {
                                return (
                                    <Link to={'/recipe/' + item.recipe} key={item.id}>
                                        <li key={item.id}>
                                            <div className="card hoverable">
                                                <div className="">
                                                    <span className="pink-text">{item.user}</span>
                                                    <br />
                                                    <span>{item.content}</span>
                                                    <div className="grey-text note-date">
                                                        {moment(item.time.toDate()).fromNow()}
                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                    </Link>
                                )
                            }
                            else {
                                return (
                                    <li key={item.id}>
                                        <div className="card">
                                            <span className="pink-text">{item.user}</span>
                                            <br />
                                            <span>{item.content}</span>
                                            <div className="grey-text note-date">
                                                {moment(item.time.toDate()).fromNow()}
                                            </div>
                                        </div>
                                    </li>
                                )
                            }

                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Notifications