import React, { Component } from 'react'

class Terms extends Component {
    state = {
    }

    render() {
        return (

            <div className="container">
                <h4 className="grey-text text-darken-3 center">Användarvilkor för veggen.se</h4>
                <p>
                    Veggen.se förbehåller sig rätten att stänga konton som inte kan bekräftas eller som inte har använts under en längre tid samt att ändra eller upphöra att tillhandahålla sina tjänster helt eller delvis.
                    Om Veggen.se väsentligt ändrar eller upphör att tillhandahålla tjänsterna avser Veggen.se att meddela dig i förväg via den mail-adress som är kopplad till kontot.

                </p>

                <p>
                    Veggen.se kan begränsa, stänga eller säga upp våra tjänster och användarkonton,
                    begränsa eller förbjuda tillgång till och din användning av våra tjänster
                    om du skapar innehåll som vi anser vara stötande, kränkande, oppassande eller irellevant för plattformen.
                </p>

                <p>
                    Veggen.se har rätt att använda innehållet i uppladdade recept så som bilder, titlar, instruktioner och beskrivningar i syfte att marknadsföra
                    Veggen.se på andra plattformar så som sociala medier eller liknande.
                </p>

            </div>
        )
    }
}

export default Terms