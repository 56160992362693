import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// Initialize Firebase
var config = {
    apiKey: "AIzaSyDf5WkLrqFataXYpVwRn5ElZbdadzwyE-Y",
    authDomain: "veggen.se",
    databaseURL: "https://laga-vego.firebaseio.com",
    projectId: "laga-vego",
    storageBucket: "laga-vego.appspot.com",
    messagingSenderId: "248012746644",
    appId: "1:248012746644:web:5f9b39d3ea91bd5b"
};
firebase.initializeApp(config);
//firebase.firestore().settings({ timestampsInSnapshots: true });
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

export default firebase;