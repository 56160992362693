import React, { Component } from 'react'

class AddIngredient extends Component {
    state = {
        content: ''
    }
    handleChange = (e) => {
        this.setState({
            content: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.addIngredient(this.state);
        this.setState({
            content: ''
        })
    }
    render() {
        return (
            <div>
                <form>
                    <label>Lägg till en ingrediens:</label>
                    <input type="text" onChange={this.handleChange} value={this.state.content} />
                    <button className="btn pink btn-floating lighten-1 z-depth-1 hoverable" onClick={this.handleSubmit}><i className="material-icons">add_circle</i></button>
                </form>
            </div>
        )
    }
}

export default AddIngredient