const initState = {
    comments: []
}

const commentReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_COMMENT':
            console.log('created comment', action.data);
            return state;
        case 'CREATE_COMMENT_ERROR':
            console.log('create comment error: ', action.err);
            return state;
        case 'GET_COMMENTS':
            //console.log('gotted comments', action.data)
            state.comments = action.data.comments;
            return state;
        case 'GET_COMMENTS_ERROR':
            console.log('get comments error: ', action.err);
            return state;
        default:
            return state;
    }
}

export default commentReducer