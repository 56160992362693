import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createRecipe } from '../../store/actions/recipeActions'
import { Redirect } from 'react-router-dom'
import Ingredients from './ingredients/Ingredients'
import AddIngredient from './ingredients/AddIngredient'
import Steps from './steps/Steps'
import AddStep from './steps/AddStep'


class CreateRecipe extends Component {
    state = {
        title: '',
        description: '',
        ingredients: [
        ],
        steps: [
        ],
        selectedFile: null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state);
        this.props.createRecipe(this.state);
        this.props.history.push('/');
    }

    deleteStep = (id) => {
        const steps = this.state.steps.filter(step => {
            return step.id !== id
        });
        this.setState({
            steps
        });
    }
    addStep = (step) => {
        step.id = this.state.steps.length + 1;

        let steps = [...this.state.steps, step];

        this.setState({
            steps
        });
    }

    deleteIngredient = (id) => {
        const ingredients = this.state.ingredients.filter(ingredient => {
            return ingredient.id !== id
        });
        this.setState({
            ingredients
        });
    }
    addIngredient = (ingredient) => {
        ingredient.id = this.state.ingredients.length + 1;

        let ingredients = [...this.state.ingredients, ingredient];

        this.setState({
            ingredients
        });
    }

    fileSelectedHandler = event => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
        if (!auth.emailVerified) return <Redirect to='/profile' />
        //if (!auth.token.email_verified) return <Redirect to='/signin' />
        return (
            <div className="container">
                <form onSubmit={this.handleSubmit} className="white">
                    <h4 className="grey-text text-darken-3">Skapa Recept</h4>
                    <div className="input-field">
                        <label htmlFor="title">Titel</label>
                        <input type="text" id="title" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="description">Beskrivning</label>
                        <textarea id="description" cols="30" rows="10" className="materialize-textarea" onChange={this.handleChange}></textarea>
                    </div>
                    <div className="image-input">
                        <h5 className="">Bild</h5>
                        <input type="file" onChange={this.fileSelectedHandler}></input>
                        <p className="grey-text">Max 5MB, Minst 1024x1024</p>
                    </div>
                    <div className="ingredients-input">
                        <h5 className="">Ingredienser</h5>
                        <Ingredients ingredients={this.state.ingredients} deleteIngredient={this.deleteIngredient} />
                        <AddIngredient addIngredient={this.addIngredient} />
                    </div>
                    <div className="steps-input">
                        <h5 className="">Instruktioner</h5>
                        <Steps steps={this.state.steps} deleteStep={this.deleteStep} />
                        <AddStep addStep={this.addStep} />
                    </div>
                    <div className="input-field">
                        <button className="waves-effect waves-dark btn pink lighten-1 z-depth-1">Ladda upp recept!</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createRecipe: (recipe) => dispatch(createRecipe(recipe))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecipe)
