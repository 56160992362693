import React from 'react'
import RecipeSummary from './RecipeSummary'
import { Link } from 'react-router-dom'
import * as _ from 'underscore';

const RecipeList = ({ recipes, sortBy }) => {
    let sortedRecipes = _.sortBy(recipes, sortBy);


    return (
        <div className="recipe-list">
            {sortedRecipes.slice(0).reverse().map(recipe => {
                return (
                    <Link to={'/recipe/' + recipe.id} key={recipe.id}>
                        <RecipeSummary recipe={recipe}></RecipeSummary>
                    </Link>
                )
            })}
        </div>
    )
}

export default RecipeList