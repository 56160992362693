import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { SideNav, SideNavItem, Button } from 'react-materialize';


const SignedInLinks = (props) => {



    return (
        <div>
            <ul className="right hide-on-med-and-down">
                <li><NavLink to='/create'>Skapa Recept</NavLink></li>
                <li><NavLink to='/' onClick={props.signOut}>Logga ut</NavLink></li>
                <li><NavLink to='/profile' className="btn btn-floating pink darken-3">
                    {props.profile.initials}
                </NavLink></li>
            </ul>
            <div className="hide-on-large-only">
                <SideNav trigger={<Button icon="menu" className="green darken-4 z-depth-0" />} options={{ closeOnClick: true }}>
                    <SideNavItem userView user={{
                        background: 'https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/green1.png?alt=media&token=0c99e970-dff4-4d62-9a6b-2caebe0089d5',
                        image: 'https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/profile.png?alt=media&token=e77cd198-656e-4d6c-8cda-d350db491344',
                        name: props.profile.firstName + " " + props.profile.lastName
                    }} />
                    <li><NavLink to='/create' className="sidenav-close"><i className="material-icons left">add_circle_outline</i>Skapa Nytt Recept</NavLink></li>
                    <li><NavLink to='/profile' className="sidenav-close"><i className="material-icons left">account_circle</i>Mitt Konto</NavLink></li>
                    <SideNavItem divider />
                    <li><NavLink to='/' className="sidenav-close btn pink darken-3 " onClick={props.signOut}>Logga ut</NavLink></li>
                </SideNav>
            </div>

        </div>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)