import React, { Component } from 'react'
import { getNewsArticles } from '../../store/actions/newsActions'
import { connect } from 'react-redux'


class NewsPanel extends Component {
    state = {
        url: 'https://newsapi.org/v2/everything?' +
            'q=vegetarisk&' +
            'language=sv&' +
            'pageSize=5&' +
            'from=2019-09-10&' +
            'sortBy=relevancy&' +
            'apiKey=b70e8058f64742edb60416687c6abe71'
    }


    componentDidMount() {
        this.getNewsArticles(this.state.url)
    }


    getNewsArticles = (url) => {
        this.props.getNewsArticles(url);
    }

    render() {
        const { articles } = this.props;
        const articleList = articles.length ? (

            articles.map(article => {
                return (
                    <div className="card" key={article.title}>
                        <div className="card-image">
                            <img src={article.urlToImage} alt="a news" />
                        </div>
                        <div className="card-content">
                            <a href={article.url}>{article.title}</a>
                            <p className="grey-text">{article.source.name}</p>
                        </div>

                    </div>
                )
            })
        ) : (
                <div className="center">No news to display...</div>
            )
        return (
            <div className="section">
                <div className="card z-depth-0">
                    <span className="card-title">Vegonyheter</span>
                    {articleList}
                    <a href="https://newsapi.org">News powered by NewsAPI.org</a>
                </div>


            </div>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        articles: state.news.articles
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getNewsArticles: (url) => dispatch(getNewsArticles(url))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsPanel)