import React from 'react'
import { Link } from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'


const Navbar = (props) => {
    const { auth, profile } = props;
    const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />

    return (
        <div>
            <nav className="nav-wrapper green darken-4">
                <div className="container">
                    <Link to='/' className="brand-logo"><img src="https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/Logotext.png?alt=media&token=3d0fd907-8f97-493e-845b-aa54416f8091" width="180" height="56" alt="VEGGEN" /></Link>
                    {links}
                </div>
            </nav>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Navbar)