import React from 'react';

const Steps = ({ steps, deleteStep }) => {

    const stepList = steps.length ? (
        steps.map(step => {
            return (
                <div className="collection-item" key={step.id} onClick={() => { deleteStep(step.id) }}>
                    <span>{step.id + '. ' + step.content}</span>
                </div>
            )
        })
    ) : (
            <p className="center grey-text">Det här receptet har inga instruktioner ännu, läggg till en nedan!</p>
        );
    return (
        <div className="steps collection">
            {stepList}
        </div>
    )
}
export default Steps;