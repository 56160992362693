import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, SideNav, SideNavItem } from 'react-materialize'

const SigneOutLinks = () => {
    return (
        <div>
            <ul className="right hide-on-med-and-down">
                <li><NavLink to='/signup'>Skapa konto</NavLink></li>
                <li><NavLink to='/signin'>Logga in</NavLink></li>
            </ul>
            <div className="hide-on-large-only">

                <SideNav trigger={<Button icon="reorder" className="green darken-4 z-depth-0" />} options={{ closeOnClick: true }}>
                    <li><NavLink to='/' className="sidenav-close" ><i className="material-icons left">dashboard</i>Startsida</NavLink></li>
                    <SideNavItem divider />
                    <li><NavLink to='/signup' className="sidenav-close"><i className="material-icons left">person_add</i>Skapa konto</NavLink></li>
                    <SideNavItem divider />
                    <li><NavLink to='/signin' className="sidenav-close btn pink darken-3 ">Logga in</NavLink></li>
                </SideNav>
            </div>
        </div>

    )
}

export default SigneOutLinks