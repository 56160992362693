import React, { Component } from 'react'
import { createComment } from '../../store/actions/commentActions'
//import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
//import axios from 'axios'
import moment from 'moment'

class CommentSection extends Component {

    state = {
        newComment: '',
        recipeId: '',
        comments: []
    }

    componentDidMount() {
        this.setState({
            recipeId: this.props.recipeId,
            comments: this.props.comments
        });

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(e.target);
        const { newComment, recipeId } = this.state;
        const { comments } = this.props
        this.props.createComment({ newComment, recipeId, comments });
        document.getElementById("post-btn").classList.add('disabled');
    }

    render() {
        const { auth, comments } = this.props;

        const commentList = comments.length ? (
            comments.slice(0).reverse().map(comment => {
                return (
                    <div className="comment card" key={comment.id}>
                        <div className="card-content">
                            <p>{comment.content}</p>
                        </div>
                        <div className="card-action grey lighten-4 grey-text">
                            <div>{comment.authorFirstName} {comment.authorLastName} {'-'} {moment(comment.time.toDate().toString()).calendar()}</div>
                        </div>
                    </div>
                )
            })
        ) : (
                <div className="comment card" key={0}>
                    <div className="card-content">
                        <p>Det här receptet har inga kommentarer ännu, skriv en du!</p>
                    </div>
                    <div className="card-action grey lighten-4 grey-text">
                        <div>Vego-Boten Viktor</div>
                    </div>
                </div>)

        if (!auth.uid) {
            return (
                <div className="comment-section">
                    <div className="card z-depth-1">
                        <div className="card-content">
                            <span className="card-title">Kommentarer</span>
                            <div className="input-field">
                                <label htmlFor="comment">Du måste vara inloggad för att kommentera...</label>
                                <textarea id="newComment" cols="30" rows="10" className="materialize-textarea disabled" onChange={this.handleChange}></textarea>
                            </div>
                            <div className="input-field">
                                <button className="btn pink lighten-1 z-depth-1 disabled">Posta kommentar!</button>
                            </div>
                            <div className="card z-depth-1">
                                {commentList}
                            </div>
                        </div>
                    </div>

                </div>
            )
        } else {
            return (
                <div className="comment-section">
                    <div className="card z-depth-1">
                        <div className="card-content">
                            <span className="card-title">Kommentarer</span>
                            <form onSubmit={this.handleSubmit} className="white">
                                <div className="input-field">
                                    <label htmlFor="comment">Skriv en kommentar...</label>
                                    <textarea id="newComment" cols="30" rows="10" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                </div>
                                <div className="input-field">
                                    <button id="post-btn" className="waves-effect waves-dark btn pink lighten-1 z-depth-1">Posta kommentar!</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="card z-depth-1">
                        {commentList}
                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => {

    return {
        auth: state.firebase.auth,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createComment: (commentInfo) => dispatch(createComment(commentInfo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentSection)