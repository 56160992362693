import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addLikeToRecipe } from '../../store/actions/recipeActions'

import moment from 'moment';
moment.suppressDeprecationWarnings = true;


class RecipeSummary extends Component {
    state = {
        imageUrl: 'https://www.pcbyte.com.au/skin/frontend/linksforce/default/images/ajax-load.gif 640w'
    }
    
    componentDidMount() {

        var storageRef = this.props.storage.ref();
        storageRef.child('thumbnail-' + this.props.recipe.id + '.jpg').getDownloadURL().then((url) => {

            this.setState({
                imageUrl: url + ' 512w'
            });
            }).catch((error) => {
            // Handle any errors
                this.setState({
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/thumbnail-icon.png?alt=media&token=65fc1333-9a73-41ee-b1fa-f08fb7781e1b 512w'
                });
            });
    }

    handleClick = (e) => {
        e.preventDefault();
        console.log(e.target);
        document.getElementById(e.target.id + '-button').classList.add('disabled');
        this.props.addLikeToRecipe(e.target.id);
    }

    render() {
        const { recipe, auth } = this.props;
        const likeButton = (!auth.uid) ? (
            <div title="Du måste logga in för att kunna gilla recept">
                <button className="btn pink btn-floating lighten-1 z-depth-1 disabled" title="Du måste logga in för att kunna gilla" id={recipe.id + '-button'}><i className="material-icons left" id={recipe.id} onClick={this.handleClick}>favorite</i></button>
            </div>
        ) : (
                recipe.likes.includes(auth.uid) ? (
                    <div title="Du har redan gillat detta recept">
                        <button className="btn pink btn-floating lighten-1 z-depth-1 disabled" id={recipe.id + '-button'}><i className="material-icons left" id={recipe.id} onClick={this.handleClick}>favorite</i></button>
                    </div>
                ) : (
                        <button className="waves-effect waves-dark btn pink btn-floating lighten-1 z-depth-1 hoverable" id={recipe.id + '-button'}><i className="material-icons left" id={recipe.id} onClick={this.handleClick}>favorite</i></button>
                    )
            )
        
        const cardtype = window.innerWidth < 430 ? ("card") : ("card horizontal")

        return (
            <div className={cardtype}>
                <div className="card-image">
                <img srcSet= {this.state.imageUrl}
                            sizes='(max-width: 768px) 314px,
                                    (max-width: 1024px) 348px,
                                    220px'
                            src={this.state.imageUrl} alt="A food" />
                </div>
                <div className="card-stacked">
                    <div className="card-content">
                        <div id="post-info">
                            <span className="card-title black-text">{recipe.title}</span>
                            <p className="grey-text">{recipe.authorFirstName} {recipe.authorLastName}  </p>
                            <p className="grey-text">{moment(recipe.createdAt.toDate().toString()).calendar()}</p>
                        </div>
                    </div>
                    <div className="card-action">
                        <div className="right" id="score">
                            <h6>{recipe.likes.length}</h6>
                        </div>
                        {likeButton}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        storage: state.storage.storage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addLikeToRecipe: (recipeId) => dispatch(addLikeToRecipe(recipeId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeSummary)