import React from 'react';

const Ingredients = ({ ingredients, deleteIngredient }) => {

    const ingredientList = ingredients.length ? (
        ingredients.map(ingredient => {
            return (
                <div className="collection-item" key={ingredient.id} onClick={() => { deleteIngredient(ingredient.id) }}>
                    <span>{ingredient.content}</span>
                </div>
            )
        })
    ) : (
            <p className="center grey-text">Det här receptet har inga ingredienser ännu, lägg till en nedan!</p>
        );
    return (
        <div className="ingredients collection">
            {ingredientList}
        </div>
    )
}
export default Ingredients;