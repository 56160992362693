import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp, googleSignIn } from '../../store/actions/authActions'

class SignUp extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signUp(this.state)
    }

    handleClick = (e) => {
        e.preventDefault();
        this.props.googleSignIn();
    }

    handleCheck = (e) => {
        var signupButton = document.getElementById("signup-button")
        var googleSignupButton = document.getElementById("google-signup")
        var signupDiv = document.getElementById("signup-wrapper")
        var googleSignupDiv = document.getElementById("google-signup-wrapper")

        if (signupButton.classList.contains("disabled")) {
            signupButton.classList.remove("disabled")
            googleSignupButton.classList.remove("disabled")
            signupDiv.title = ""
            googleSignupDiv.title = ""
        } else {
            signupButton.classList.add("disabled")
            googleSignupButton.classList.add("disabled")
        }
    }

    render() {
        const { auth, authError } = this.props;
        if (auth.uid) return <Redirect to='/' />

        return (
            <div className="container">
                <h4 className="grey-text text-darken-3 center">Skapa konto</h4>
                <div className="center switch">
                    <label>
                        Jag har läst och godkänner <a href="terms">användarvillkoren</a> för veggen.se
                        <input id="terms-checkbox" type="checkbox" onChange={this.handleCheck} />
                        <span className="lever"></span>
                    </label>
                </div>
                <div id="google-signup-wrapper" title="Du måste godkänna användarvillkoren för att skapa ett konto" className="googleSignIn center">
                    <br />
                    <button id="google-signup" className="disabled waves-effect waves-dark btn blue z-depth-1" onClick={this.handleClick}>Skapa konto med Google</button>
                </div>
                <p className="grey-text center">Eller med mail-adress och lösenord:</p><br />
                <form onSubmit={this.handleSubmit} className="white">
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Lösenord</label>
                        <input type="password" id="password" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="firstName">Förnamn</label>
                        <input type="text" id="firstName" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="lastName">Efternamn</label>
                        <input type="text" id="lastName" onChange={this.handleChange} />
                    </div>
                    <div id="signup-wrapper" title="Du måste godkänna användarvillkoren för att skapa ett konto" className="input-field center">
                        <button id="signup-button" className="disabled waves-effect waves-dark btn pink lighten-1 z-depth-1">Skapa konto</button>
                        <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser)),
        googleSignIn: () => dispatch(googleSignIn())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
