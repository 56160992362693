const initState = {
    articles: []
}

const newsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_NEWS':
            state.articles = action.data.articles;
            return state;
        case 'GET_NEWS_ERROR':
            console.log('get news error: ', action.err);
            return state;
        default:
            return state;
    }
}

export default newsReducer