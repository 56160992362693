import axios from 'axios'
//const queryString = require('query-string');

export const getNewsArticles = (url) => {
    return (dispatch, getState) => {
        axios.get(url)
            .then(res => {
                let data = {
                    articles: res.data.articles ? (res.data.articles) : (
                        [{
                            id: 0,
                            content: 'Det här receptet har inga nyheter ännu, skriv en du!',
                            authorFirstName: 'Vego-Boten',
                            authorLastName: 'Viktor'
                        }])
                };
                dispatch({ type: 'GET_NEWS', data })
            }).catch((err) => {
                dispatch({ type: 'GET_NEWS_ERROR', err });
            })
    }
};