import axios from 'axios'
const queryString = require('query-string');

export const createRecipe = (recipe) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;

        const {
            selectedFile,
            ...recipeWithoutSelectedFile
        } = recipe;

        //make async call to database
        firestore.collection('recipes').add({
            ...recipeWithoutSelectedFile,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            authorId: authorId,
            likes: [authorId],
            comments: [],
            createdAt: new Date()
        }).then((docRef) => {
            const filename = docRef.id + '.jpg'
            const fileData = new FormData();
            fileData.append('image', recipe.selectedFile, filename);
            axios.post('https://us-central1-laga-vego.cloudfunctions.net/uploadFile', fileData)
                .then(res => {
                    console.log(res);
                    dispatch({ type: 'CREATE_RECIPE', recipe });
                });
        }).catch((err) => {
            dispatch({ type: 'CREATE_RECIPE_ERROR', err });
        })
    }
};

export const addLikeToRecipe = (recipeId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //const firebase = getFirebase();
        const profile = getState().firebase.profile;
        const userId = getState().firebase.auth.uid;

        const data = queryString.stringify({
            userId: userId,
            recipeId: recipeId,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName
        })

        console.log('lajkar');
        let axiosConfig = {

            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            withCredentials: true
        };

        console.log(data)
        axios.post('https://us-central1-laga-vego.cloudfunctions.net/addLikeToRecipe', data, axiosConfig)
            .then((res) => {
                console.log(res);
                dispatch({ type: 'ADD_LIKE', data });
            }).catch((err) => {
                dispatch({ type: 'ADD_LIKE_ERROR', err });
            })




    }
};

export const deleteRecipe = (id) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        //const profile = getState().firebase.profile;
        //const authorId = getState().firebase.auth.uid;
        //make async call to database
        firestore.collection('recipes').doc(id).delete()
            .then(() => {
                dispatch({ type: 'DELETE_RECIPE', id });
            }).catch((err) => {
                dispatch({ type: 'DELETE_RECIPE_ERROR', err });
            })
    }
};