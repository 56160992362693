//import axios from 'axios';
//const queryString = require('query-string');



export const createComment = (commentInfo) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        //const authorId = getState().firebase.auth.uid;

        const comment = {
            id: commentInfo.comments.length + 1,
            content: commentInfo.newComment,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            time: new Date()
        };

        //make async call to database
        firestore.collection('recipes').doc(commentInfo.recipeId).update({
            comments: firestore.FieldValue.arrayUnion(comment)
        }).catch((err) => {
            dispatch({ type: 'CREATE_RECIPE_ERROR', err });
        })
    }
};