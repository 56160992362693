const initState = {
    storage: ""
}

const storageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_STORAGE':
            state.storage = action.storage;
            return state;
        case 'GET_STORAGE_ERROR':
            console.log('get storage error: ', action.err);
            return state;
        default:
            return state;
    }
}

export default storageReducer