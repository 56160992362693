import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import CommentSection from './CommentSection'
import { deleteRecipe } from '../../store/actions/recipeActions'
import { getStorageRef } from '../../store/actions/storageActions'


class RecipeDetails extends Component {
    state = {
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/loading_zoom.gif?alt=media&token=c1e23a16-2136-43f6-9438-1f4c98ed2365"
    }

    componentDidMount() {
        this.getStorageRef();

    }


    componentDidUpdate(prevProps, prevState) {
        var storageRef = this.props.storage.ref();

        storageRef.child('cover-' + this.props.id + '.jpg').getDownloadURL().then((url) => {
            if (this.state.imageUrl !== url)
                this.setState({
                    imageUrl: url
                });
        }).catch((error) => {
            if (this.state.imageUrl !== "https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/cover-image_missing.png?alt=media&token=61ed422b-d484-4d93-a224-73f8d08dff82")
                this.setState({
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/laga-vego.appspot.com/o/cover-image_missing.png?alt=media&token=61ed422b-d484-4d93-a224-73f8d08dff82'
                });
        });
    }


    getStorageRef = () => {
        this.props.getStorageRef();
    }

    deleteRecipe = (id) => {
        this.props.deleteRecipe(id);
        this.props.history.push('/');
    }
    handleDelete = (e) => {
        e.preventDefault();
        if (window.confirm("Är du säker på att du vill ta bort detta recept?")) {
            this.deleteRecipe(this.props.id);
        } else {
        }
    }

    render() {
        const { auth, recipe, id } = this.props;

        if (recipe) {

            const recipeFooter = (auth.uid === recipe.authorId) ? (
                <div className="card-action grey lighten-4 grey-text">
                    <div>Posted by you.</div>
                    <div>{moment(recipe.createdAt.toDate().toString()).calendar()}</div>
                    <div className="container center">
                        <button className="waves-effect waves-dark btn red darken-1" onClick={this.handleDelete}><i className="material-icons left">delete</i> Ta bort Recept</button>

                    </div>
                </div>
            ) : (
                    <div className="card-action grey lighten-4 grey-text">
                        <div>Posted by {recipe.authorFirstName} {recipe.authorLastName}</div>
                        <div>{moment(recipe.createdAt.toDate().toString()).calendar()}</div>
                    </div>
                )

            return (
                <div className="container section recipe-details">
                    <div className="card z-depth-1">
                        <div className="card-image">
                            <img src={this.state.imageUrl} alt="A food" />
                            <span className="card-title green darken-4">{recipe.title}</span>

                        </div>
                        <div className="card-content">
                            <p>{recipe.description}</p>
                            <br></br>
                            <span className="card-title">Ingredienser:</span>
                            <ul>
                                {recipe.ingredients.map(function (d, idx) {
                                    return (<li key={idx}>{d.content}</li>)
                                })}
                            </ul>

                            <span className="card-title">Gör såhär:</span>
                            <ol>
                                {recipe.steps.map(function (d, idx) {
                                    return (<li key={idx}>{d.content}</li>)
                                })}
                            </ol>


                        </div>
                        {recipeFooter}
                    </div>
                    <CommentSection recipeId={id} comments={recipe.comments} />
                </div>

            )
        } else {
            return (
                <div className="container center">
                    <p>Loading...</p>
                </div>
            )
        }
    }

}

const mapStateToProps = (state, ownProps) => {
    //console.log(state);
    const id = ownProps.match.params.id;
    const recipes = state.firestore.data.recipes;
    const recipe = recipes ? recipes[id] : null
    return {
        auth: state.firebase.auth,
        recipe: recipe,
        id: id,
        storage: state.storage.storage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteRecipe: (recipe) => dispatch(deleteRecipe(recipe)),
        getStorageRef: () => dispatch(getStorageRef())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'recipes' }
    ])
)(RecipeDetails)
