const initState = {
    recipes: []
}

const recipeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_RECIPE':
            console.log('created recipe', action.recipe);
            return state;
        case 'CREATE_RECIPE_ERROR':
            console.log('create recipe error: ', action.err);
            return state;
        case 'DELETE_RECIPE':
            console.log('deleted recipe', action.id);
            return state;
        case 'DELETE_RECIPE_ERROR':
            console.log('delete recipe error: ', action.err);
            return state;
        case 'ADD_LIKE':
            console.log('liked recipe', action.data);
            return state;
        case 'ADD_LIKE_ERROR':
            console.log('like recipe error: ', action.err);
            return state;
        default:
            return state;
    }
}

export default recipeReducer