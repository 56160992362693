//import axios from 'axios'
//const queryString = require('query-string');


export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            // Get the user's ID token as it is needed to exchange for a session cookie.
            /*
            firebase.auth().currentUser.getIdToken( true).then(function (idToken) {
                const expiresIn = 60 * 60 * 24 * 5 * 1000;
                document.cookie = '__session=' + idToken + ';max-age=' + expiresIn;

            });
            */
            dispatch({ type: 'LOGIN_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err });
        })
    }
}

export const googleSignIn = (credentials) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        var provider = new firebase.auth.GoogleAuthProvider();


        firebase.auth().signInWithPopup(provider).then(function (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = result.credential.accessToken;
            var user = result.user;
            var userFullName = user.displayName.split(" ");

            firestore.collection('users').doc(user.uid).set({
                firstName: userFullName[0],
                lastName: userFullName[1],
                initials: userFullName[0][0] + userFullName[1][0]
            })
        }).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' });
        }).catch(function (err) {
            //var errorCode = err.code;
            //var errorMessage = err.message;
            // The email of the user's account used.
            //var email = err.email;
            // The firebase.auth.AuthCredential type that was used.
            //var credential = err.credential;
            dispatch({ type: 'LOGIN_ERROR', err });
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' });
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp) => {
            //make async call to database
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: newUser.firstName[0] + newUser.lastName[0]
            })
        }).then(() => {
            var user = firebase.auth().currentUser;

            user.sendEmailVerification().then(function () {
                // Email sent.
                console.log('email verification sent')
            }).catch(function (error) {
                // An error happened.
                console.log(error);
            });
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch(err => {
            dispatch({ type: 'SIGNUP_ERROR', err })
        })
    }
}

export const resetPassword = (email) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        var auth = firebase.auth();
        auth.sendPasswordResetEmail(email).then(function () {
            // Email sent.
            dispatch({ type: 'PWD_RESET_SUCCESS' })
        }).catch(function (error) {
            // An error happened.
            dispatch({ type: 'PWD_RESET_ERROR' })
        });
    }
}

export const deleteAccount = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        var user = firebase.auth().currentUser;
        user.delete().then(function () {
            // Email sent.
            dispatch({ type: 'ACCOUNT_DELETE_SUCCESS' })
        }).catch(function (error) {
            // An error happened.
            dispatch({ type: 'ACCOUNT_DELETE_ERROR' })
        });
    }
}