import React, { Component } from 'react'
import NewsPanel from './NewsPanel'
import Notifications from './Notifications'
import RecipeList from '../recipes/RecipeList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { getStorageRef } from '../../store/actions/storageActions'


class DashBoard extends Component {
    state = {
        sortBy: 'likes.length'
    }

    componentDidMount() {
        this.getStorageRef()
    }

    getStorageRef = () => {
        this.props.getStorageRef();
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            sortBy: e.target.id
        });
        document.getElementById("createdAt").classList.remove('disabled');
        document.getElementById('likes.length').classList.remove('disabled');
        document.getElementById(e.target.id).classList.add('disabled');

    }

    handleCheck = (e) => {
        //e.preventDefault();
        if (this.state.sortBy === "likes.length") {
            this.setState({
                sortBy: "createdAt"
            });
        } else {
            this.setState({
                sortBy: "likes.length"
            });
        }

    }

    render() {
        //console.log(this.props)
        const { recipes, notifications } = this.props;

        return (
            <div className="dashboard container">
                <div className="row">
                    <div className="col s12 m9">
                        <div className="center switch">
                            <label>
                                Popularitet
                                <input type="checkbox" onChange={this.handleCheck} />
                                <span className="lever"></span>
                                Senaste
                            </label>
                        </div>
                        <RecipeList recipes={recipes} sortBy={this.state.sortBy} />
                    </div>
                    <div className="col s12 m3">
                        <Notifications notifications={notifications} />
                        <div className="">
                            <NewsPanel></NewsPanel>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log(state);
    return {
        recipes: state.firestore.ordered.recipes,
        notifications: state.firestore.ordered.notifications
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStorageRef: () => dispatch(getStorageRef())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'recipes', limit: 25, orderBy: ['createdAt', 'desc'] },
        { collection: 'notifications', limit: 5, orderBy: ['time', 'desc'] }
    ])
)(DashBoard)